<template>
    <html>
      <body class="" style="background: #FFF">
        <Header :localNavigation="false" />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
           
            <div class="bg-container col-span-6">
                <div class="flex ml-5 mt-5 cursor-pointer" @click="redirect_history()" >
                    <img src="@/assets/back-arrow.svg">Atrás
                </div>
                <Transition>
                    <div v-if="isLoading" class="w-1/2 mx-auto">
                        
                        <img src="@/assets/logoLoading.png" class="logo_cabsa w-1/2 mx-auto my-32 animate-pulse" />
                    </div>
                    <div v-else class="w-full md:w-4/5 mx-auto h-auto py-16 grid grid-cols-1 md:grid-cols-2">
                        <div class="px-10 md:px-5 lg:px-5">
                            <p class="text-3xl font-bold mb-10 RobotoBold">Cotización</p>
                            <div class="shadow px-4 py-2 border rounded-md grid grid-cols-1 mb-10 h-32">
                                <div class="grid">
                                    <p class="RobotoRegular text-xl my-2">Prima Total</p>
                                    <div class="grid grid-cols-2">
                                        <div class="flex"><p id="input_total_anual" class="RobotoLight text-md text-black mr-2 mt-auto my-auto">{{ $filters.formatCurrencyWithDecimal(quoteDetail.total_anual) }} </p> <span class="text-gray-400 mt-auto my-auto">Anual</span></div>
                                        <div class="flex"><p id="input_total_month" class="RobotoLight text-md text-black mr-2 mt-auto my-auto">{{ $filters.formatCurrencyWithDecimal(quoteDetail.total_month)}} </p> <span class="text-gray-400 mt-auto my-auto">Mensual</span></div>
                                    </div>
                                </div>
                            </div>

                            <div class="shadow px-4 py-2 border rounded-md grid grid-cols-1 mb-10 ">
                                <div class="grid">
                                    <p class="RobotoRegular text-xl my-2">Beneficios</p>
                                    <div class="block">
                                        <span class="RobotoLight text-md text-black mt-auto mb-2 mr-2 uppercase " v-if="quoteDetail.coverages.length==0">Sin beneficios adicionales</span>
                                        <div class="w-full RobotoLight text-md text-black mt-auto mb-2 mr-2 uppercase " v-for="(coverage, index) in quoteDetail.coverages" :key="index"> 
                                            <li v-if="quoteDetail.errors_coverages[coverage.toLowerCase()] == UNDEFINED"> {{ coverage }} </li>
                                        </div>
                                        
                                        <div class="mb-2 w-full" v-for="(text, index) in quoteDetail.errors_coverages" :key="index">
                                            <div v-if="quoteDetail.errors_coverages[index.toLowerCase()] != UNDEFINED" class="w-full flex cursor-pointer" @click="added_benefit.show=!added_benefit.show">
                                                <div>
                                                    <li class="w-full RobotoLight text-md text-black line-through mt-auto mb-2 mr-2 uppercase"> {{ index.toUpperCase() }} </li>
                                                    <div class="grid grid-cols-7">
                                                        <div class="flex col-start-1 col-end-1 ml-5 my-3">
                                                            <svg src="@/assets/exclamation-triangle.svg" width="30" height="30" fill="currentColor" class="bi bi-exclamation-triangle text-yellow-500" viewBox="0 0 16 16">
                                                                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"></path>
                                                                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"></path>
                                                            </svg>
                                                        </div>
                                                        <div class="flex col-start-2 col-end-8 text-red-600 text-sm mt-2 ml-6">{{text[0]}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid" v-show="quoteDetail.extraprima != ''">
                                    <p class="RobotoRegular text-xl my-2"> Extraprima</p>
                                    <div class="flex">
                                        <span class="RobotoLight text-md mt-auto mb-2 mr-2 uppercase flex text-red-700 font-bold"> {{quoteDetail.extraprima}} </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-yellow-500">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="grid" v-show="quoteDetail.prima_invalidez == '0.0'">
                                    <p class="RobotoRegular text-xl my-2">Invalidez</p>
                                    <div class="flex">
                                        <span class="RobotoLight text-md mt-auto mb-2 mr-2 uppercase flex text-red-700 font-bold"> No aplica invalidez </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-yellow-500">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg>
                                    </div>
                                </div>

                                
                            </div>

                            <div class="shadow px-4 py-2 border rounded-md grid grid-cols-1 mb-10 h-32">
                                <div class="grid">
                                    <p class="RobotoRegular text-xl my-2">Suma Asegurada Total</p>
                                    <div class="grid grid-cols-2">
                                        <div class="flex"><p id="input_assured_amount" class="RobotoLight text-md text-black mr-2 mt-auto my-auto">{{ $filters.formatCurrencyWithDecimal(quoteDetail.assured_amount) }} </p> <span class="text-gray-400 mt-auto my-auto">Total</span></div>
                                        <div class="flex"><p class="RobotoLight text-md text-black mr-2 mt-auto mb-2"></p> <span class="text-gray-400 mt-auto mb-2"></span></div>
                                    </div>
                                </div>
                            </div>
                            <input type="button" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-5 w-full md:w-full h-14 md:h-14  rounded text-center font-medium text-xl" @click="next" value="Continuar">  
                        </div>
                        
                        <div class="px-10 py-16 hidden md:block">
                            <img :src="require('@/assets/Cuenta/vidaSimple/quotation.svg')" alt="FONCABSA SFC" class="mx-auto">
                        </div>
                    </div>
                </Transition>
                
            </div>
        </div>
      </body>
    </html>
</template>

<script>

import { onMounted, onBeforeMount, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import Questions from "@/components/Shared/Products/Quote/QuoteQuestions.vue";
import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
import Loading from '@/components/Loading/VueLoading.vue';

import Issuance from '@/classes/Issuance.js';
import { getLocalStorage, getSessionStorage } from '@/helpers/storage.js';

export default {
    setup(){
        const router = useRouter();
        const route = useRoute();
        const category = ref('');
        const isLoading = ref(true);
        const quoteDetail = ref({
            assured_amount: 0,
            total_anual: 0,
            total_month: 0,
            coverages: [],
            extraprima:"",
            prima_invalidez:"",
            errors_coverages:[]
        });

        const coverages = ref('');
        const issuanceClass = ref(null);

        let counterGoBack = ref(0);
        let continueQuote = ref(false);
        let role = ref("");
        let productName = ref("");

        onBeforeMount(()=>{
            category.value = getCategory(false);
            if(category.value !== ""){
                
                let quote = getQuoteFromStorage();
                coverages.value = quote.coverages;

                const detail = getLocalStorage('quote-description');
                issuanceClass.value = new Issuance({});
                issuanceClass.value.save(category.value, issuanceClass.value);

                if(quote != null){
                    role.value = route.params.role;
                    getQuoteInformation(category.value, detail);
                }
            }
        });

        onMounted(()=>{
            setTimeout(() => {
                isLoading.value = false;
            }, 2000);
        });

        const getQuoteFromStorage = () => {
            const product = getCategory(false);
            return getLocalStorage(`quote-${product}`);
        }

        const getCategory = (replace = true) => {
            let product = route.params.product;
            return replace? product.replaceAll("-", " "): product;
        }

        const getQuoteInformation = (type, detail) => {
            if(type == 'vida-simple'){
                getQuoteDetailJson(detail.data.total_anual, detail.parameters.assured_amount, detail.parameters.coverages, "","", detail.errors_coverages)
            }else{
                getQuoteDetailJson(detail.data.prima_total, detail.data.suma_asegurada, coverages.value, detail.data.extraprima, detail.data.prima_invalidez, [])
            }

            productName.value = route.params.product;
        }

        const getQuoteDetailJson = (total_anual, assured_amount, coverages, extraprima, prima_invalidez, errors_coverages) => {
            const totalMonth = total_anual / 12;
            quoteDetail.value.total_anual = total_anual;
            quoteDetail.value.assured_amount = assured_amount;
            quoteDetail.value.total_month = totalMonth;
            quoteDetail.value.coverages = coverages;
            quoteDetail.value.extraprima = extraprima;
            quoteDetail.value.prima_invalidez = prima_invalidez;
            console.log("errors_coverages", errors_coverages)
            quoteDetail.value.errors_coverages = errors_coverages
        }

        const next = () => {
            router.push({ path: `/${role.value}/productos/${productName.value}/emision/direccion`})
        }

        const redirect_history = () => router.go(-1);

        return {
            category,
            counterGoBack,
            continueQuote,
            isLoading,
            quoteDetail,
            next,
            redirect_history
        }
    },
    components:{
        Header,
        MenuLateralMobile,
        MenuLateral,
        Questions,
        Loading
    }
}
</script>

<style scoped>
#logo_cabsa{
    animation: zoom_in_and_out 4s infinite forwards
}

@keyframes zoom_in_and_out{

    0% {
        max-width:200px;
        height: 200px;
    }

    25% {
        max-width:300px;
        height: 300px;
    }

    50% {
        max-width:200px;
        height: 200px;
    }

    75% {
        max-width:300px;
        height: 300px;
    }

    100% {
        max-width:200px;
        height: 200px;
    }
}
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0.3;
}
button {
    font-size: 19px!important;
}
</style>